import {
  Button,
  Form,
  notification,
  Select,
  Empty,
  Input,
  Drawer,
  Divider,
  Row,
  Col,
  DatePicker,
  Modal,
  Pagination,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState, createContext } from "react";
import $axios from "../../services/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { moveToFirst, removeDiacritics } from "../../helpers/utils";
import {
  faLocationDot,
  faSearch,
  faAngleLeft,
  faArrowUp,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import ChooseHotel from "../Common/ChooseHotel";
import HotelItem from "../Common/HotelItem";
import "react-calendar/dist/Calendar.css";
import locale from "antd/es/date-picker/locale/vi_VN";
import "moment/locale/vi"; // Import locale tiếng Việt
import _ from "lodash";
import CalendarCustom from "../Common/Calendar/CalendarCustom";
import CustomInputNumber from "../Common/CustomInputNumber";
import axios from "axios";
const ReachableContext = createContext(null);

const Booking = ({ setLoading, loading, setStep }) => {
  const [countries, setCountries] = useState([]);
  const [filterCountries, setFilterCountries] = useState([]);
  const [chooseAddStep, setChooseAddStep] = useState(1);
  const [hotels, setHotels] = useState([]);
  const [openChooseHotel, setOpenChooseHotel] = useState(false);
  const [hotelSelected, setHotelSelected] = useState(null);
  const [form] = Form.useForm();
  const [provinces, setProvinces] = useState([]);
  const [filterProvinces, setFilterProvinces] = useState([]);
  const [searched, setSearched] = useState(false);
  const [addPrice, setAddPrice] = useState(0);
  const [openDrawerChooseAdd, setOpenDrawerChooseAdd] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10); // Số item trên mỗi trang
  const [loadingLocation, setLoadingLocation] = useState(false);
  const [displayHotels, setDisplayHotels] = useState([]); // Data to display
  const [valueCalendar, setValueCalendar] = useState(new Date());
  const [openCalendar, setOpenCalendar] = useState(false);
  const [typeCalendar, setTypeCalendar] = useState("");
  const [minDateCalendar, setMinDateCalendar] = useState(new Date());
  const [countryDetect, setCountryDetect] = useState("");
  const [provinceDetect, setProvinceDetect] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [filterHotel, setFilterHotel] = useState([
    {
      value: "",
      label: "Tất cả",
    },
  ]);
  const [modal, contextHolder] = Modal.useModal();
  const [filter, setFilter] = useState({
    address: "",
    country: "",
    star: 6,
  });
  const [hotelSearch, setHotelSearch] = useState("");

  useEffect(() => {
    detectLocation();
    getCountries();
    getAddPrice();
  }, []);

  useEffect(() => {
    if (filter.star && filter.country && filter.address) {
      handleGetHotels();
      setHotelSearch("");
      setSearched(false);
    }
  }, [filter]);

  useEffect(() => {
    if (countries.length > 0 && countryDetect && provinceDetect) {
      const countryId = _.get(
        _.find(countries, (c) => c.name === countryDetect),
        "id",
        ""
      );
      if (countryId) {
        processLocationDetect(countryId);
      }
    }
  }, [countries, countryDetect, provinceDetect]);

  useEffect(() => {
    if (chooseAddStep === 1 && searched) {
      setFilterCountries(
        _.filter(countries, (item) => {
          return _.includes(
            _.toLower(removeDiacritics(`${item.name} ${item.code}`)),
            _.toLower(removeDiacritics(searchTerm))
          );
        })
      );
    }
    if (chooseAddStep === 2 && searched) {
      setFilterProvinces(
        _.filter(provinces, (item) => {
          return _.includes(
            _.toLower(removeDiacritics(item.name)),
            _.toLower(removeDiacritics(searchTerm))
          );
        })
      );
    }
  }, [searchTerm, chooseAddStep, searched]);

  const processLocationDetect = async (countryId) => {
    setLoading(true);
    try {
      const res = await $axios.$get(
        `/vn-travel/hotel-content/provinces?countryId=${countryId}`
      );
      let _provinces = moveToFirst(res.data.data.items, "id", 11); // Hà Nội
      _provinces = moveToFirst(res.data.data.items, "id", 33); // Hồ Chí Minh
      setProvinces(_provinces);
      setFilterProvinces(res.data.data.items);
      const provinceName = _.get(
        _.find(res.data.data.items, (p) => p.name === provinceDetect),
        "name",
        ""
      );
      const countryCode = _.get(
        _.find(countries, (p) => p.id === countryId),
        "code",
        ""
      );
      if (provinceName && countryCode) {
        setFilter({
          ...filter,
          country: countryCode,
          address: provinceName,
        });
        setChooseAddStep(2);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  // Hàm phát hiện tỉnh/thành phố
  const detectLocation = async () => {
    if (!navigator.geolocation) {
      console.error("Geolocation is not supported by your browser");
      return;
    }

    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        setLoading(true);
        try {
          // Thay YOUR_API_KEY bằng Google Maps API Key của bạn
          const apiKey = "AIzaSyDjg6691DQySNdPewrzG66RJmxZNuLUjKw";
          const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}&language=vi`;

          const response = await axios.get(url);
          const results = response.data.results;

          if (results.length === 0) {
            console.error("No location data found.");
            return;
          }

          // Tìm thông tin tỉnh/thành phố
          const province = results[0].address_components.find((component) =>
            component.types.includes("administrative_area_level_1")
          );

          // Tìm thông tin quốc gia
          const country = results[0].address_components.find((component) =>
            component.types.includes("country")
          );

          setProvinceDetect(_.get(province, "long_name", ""));
          setCountryDetect(_.get(country, "long_name", ""));
        } catch (error) {
          console.error("Error fetching location data:", error);
        } finally {
          setLoading(false);
        }
      },
      (error) => {
        console.error("Error getting geolocation:", error.message);
      }
    );
  };

  const handleGetHotels = async () => {
    try {
      const res = await $axios.$get(
        `/vn-travel/GetHotelMytours?name=&city=${filter.address}&startNumber=${
          _.get(filter, "star", 6) === 6 ? "" : _.get(filter, "star", 6)
        }`
      );
      const _hotels = res.data.data;
      setFilterHotel([
        { value: "", label: "Tất cả" },
        ..._hotels.map((h) => {
          return {
            value: h.id,
            label: h.name,
          };
        }),
      ]);
      setHotels(_hotels);
    } catch (error) {
      setHotels([]);
      notification.error({
        description: `Có lỗi xảy ra vui lòng thử lại!`,
      });
    } finally {
    }
  };
  const handleSearchHotel = async (textSearch) => {
    if (!filter.address && textSearch) {
      notification.warning({
        description: `Vui lòng chọn địa điểm trước!`,
      });
      return;
    }
  };

  const onOpenChange = (type) => {
    setValueCalendar(form.getFieldValue(type) ?? new Date());
    setOpenCalendar(true);
    setTypeCalendar(type);
    if (type === "returnDate") {
      setMinDateCalendar(new Date(form.getFieldValue("departDate")));
    } else {
      setMinDateCalendar(new Date());
    }
  };

  const setValue = (value) => {
    form.setFieldValue(typeCalendar, dayjs(value));
    setValueCalendar(value);
    if (
      typeCalendar === "departDate" &&
      dayjs(value) >= form.getFieldValue("returnDate")
    ) {
      const newReturnDate = dayjs(value).add(1, "day");
      form.setFieldValue("returnDate", newReturnDate);
    }
    setOpenCalendar(false);
  };

  const getAddPrice = async () => {
    try {
      const res = await $axios.$get(`/payment/getAddPrice`);
      if (res.data.data.data.status === 1) {
        setAddPrice(_.get(res, "data.data.data.amount", 0));
      }
    } catch (error) {
    } finally {
    }
  };

  const handleGetListHotelAvailable = async (values) => {
    if (values.numRooms > (values.numAdults)) {
      notification.warning({
        description: `Số phòng cần nhỏ hơn hoặc bằng số người lớn!`,
      });
      return;
    }
    if (!filter.address) {
      notification.warning({
        description: `Vui lòng chọn tỉnh thành cụ thể!`,
      });
      return;
    }

    setLoading(true);
    //TODO: Check riêng khách sạn 29739
    const specialHotelId = 29739;
    let specialHotel = hotels.find((h) => h.id === specialHotelId);
    let otherHotels = hotels.filter((h) => h.id !== specialHotelId);
    //TODO: END

    let hotelsId = hotelSearch
    ? [[hotelSearch]]
    : _.chunk(otherHotels.map((h) => h.id), 20);
    // let hotelsId = hotelSearch
    //   ? [[hotelSearch]]
    //   : _.chunk(
    //       hotels.map((h) => h.id),
    //       20
    //     );

    const body = {
      StayCheckIn: dayjs(values.departDate).format("YYYY-MM-DD"),
      StayCheckOut: dayjs(values.returnDate).format("YYYY-MM-DD"),
      Nationality: filter.country,
      OccupanciesAdults: values.numAdults + values.numChilds,
      OccupanciesChildren: 0,
      LanguageCode: "vi",
      NumberOfUnits: values.numRooms,
      RealTimeSearch: true,
    };

    // lấy khách sạn có phòng
    const callApiForChunk = async (chunk) => {
      try {
        const response = await $axios.$post("/vn-travel/hotel-avail", {
          ...body,
          Hotels: chunk,
        });
        return _.get(response, "data.data.hotels", []);
      } catch (error) {
        console.error("Error:", error.message);
        return [];
      }
    };

    // lấy thông tin cho khách sạn
    const callApiForChunkProperty = async (chunk) => {
      try {
        let query = "";
        _.forEach(chunk, (id, index) => {
          query += `propertyId=${id}&`;
        });
        const response = await $axios.$get(
          `/vn-travel/hotel-content/properties/content?${query}`
        );
        return _.get(response, "data.data.items", []);
      } catch (error) {
        console.error("Error:", error.message);
        return [];
      }
    };

    // Hàm xử lý tất cả mảng cùng lúc
    const processAllChunks = async () => {
      try {
        const results = await Promise.all(hotelsId.map(callApiForChunk));
        let allHotelsHasData = _.filter(
          _.flatten(results),
          (item) => item.status !== "NoRoom"
        );
        
        //TODO: Check riêng khách sạn 29739
      if (specialHotel) {
        const specialHotelData = await callApiForChunk([specialHotelId]);
        if (specialHotelData.length > 0 && specialHotelData[0].status !== "NoRoom") {
          allHotelsHasData.push(specialHotelData[0]);
        }
      }
      //TODO: END

        const listIdsHotelHasData = _.chunk(
          allHotelsHasData.map((h) => h.id),
          20
        );

        const hotelProperty = _.flatten(
          await Promise.all(listIdsHotelHasData.map(callApiForChunkProperty))
        );

        allHotelsHasData = allHotelsHasData.map((hotel) => {
          const property = hotelProperty.find((h) => h.id === hotel.id);
          if (property) {
            delete property.rooms;
          }
          hotel.rooms = hotel.rooms.map((room) => {
            room.rates = room.rates.map((rate) => {
              rate["rateAfterTaxTotalNew"] =
                rate["rateAfterTaxTotal"] + addPrice;
              rate["rateBeforeTaxTotalNew"] =
                rate["rateBeforeTaxTotal"] + addPrice;
              return rate;
            });
            room["minPrice"] = _.minBy(
              room.rates,
              "rateAfterTaxTotalNew"
            ).rateAfterTaxTotalNew;
            room["maxPrice"] = _.maxBy(
              room.rates,
              "rateAfterTaxTotalNew"
            ).rateAfterTaxTotalNew;
            return room;
          });
          hotel["minPrice"] = Math.min(...hotel.rooms.map((r) => r.minPrice));
          hotel["maxPrice"] = Math.max(...hotel.rooms.map((r) => r.maxPrice));
          return _.merge({}, hotel, property ? property : {});
        });
        setDisplayHotels(_.orderBy(allHotelsHasData, ["maxPrice"], "asc"));
        setSortBy("price:asc");
      } catch (error) {
        setDisplayHotels([]);
        console.error("Error processing some chunks:", error.message);
      } finally {
        setLoading(false);
        setSearched(true);
      }
    };

    processAllChunks();
  };

  const getProvinces = async (countryId) => {
    setLoadingLocation(true);
    try {
      const res = await $axios.$get(
        `/vn-travel/hotel-content/provinces?countryId=${countryId}`
      );
      let _provinces = moveToFirst(res.data.data.items, "id", 11); // Hà Nội
      _provinces = moveToFirst(res.data.data.items, "id", 33); // Hồ Chí Minh
      setProvinces(_provinces);
      setFilterProvinces(res.data.data.items);
    } catch (error) {
    } finally {
      setLoadingLocation(false);
    }
  };

  const getCountries = async () => {
    setLoading(true);
    try {
      const res = await $axios.$get(`/vn-travel/hotel-content/countries`);
      const _countries = moveToFirst(res.data.data.items, "id", 1);
      setCountries(_countries);
      setFilterCountries(_countries);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleSelectAdd = (value, type = "country" | "province") => {
    if (type === "country") {
      setChooseAddStep(2);
      getProvinces(value);
      setFilter({
        ...filter,
        country: _.find(countries, (c) => c.id === value).code,
        address: "",
      });
    } else {
      setOpenDrawerChooseAdd(false);
      setFilter({
        ...filter,
        address: _.get(
          _.find(provinces, (c) => c.id === value),
          "name"
        ),
      });
    }
    setSearchTerm("");
  };

  const handleSortPriceHotel = () => {
    const _displayHotels = _.cloneDeep(displayHotels);
    if (sortBy === "price:desc") {
      setDisplayHotels(_.orderBy(_displayHotels, ["maxPrice"], "asc"));
      setSortBy("price:asc");
    } else {
      setDisplayHotels(_.orderBy(_displayHotels, ["maxPrice"], "desc"));
      setSortBy("price:desc");
    }
  };

  const handleSortStarHotel = () => {
    const _displayHotels = _.cloneDeep(displayHotels);
    if (sortBy === "star:desc") {
      setDisplayHotels(_.orderBy(_displayHotels, ["starNumber"], "asc"));
      setSortBy("star:asc");
    } else {
      setDisplayHotels(_.orderBy(_displayHotels, ["starNumber"], "desc"));
      setSortBy("star:desc");
    }
  };

  return (
    <div className="flex flex-col items-center mt-[0px] booking absolute left-1/2 transform -translate-x-1/2 w-full pb-[64px]">
      <CalendarCustom
        openCalendar={openCalendar}
        setOpenCalendar={setOpenCalendar}
        typeCalendar={typeCalendar}
        form={form}
        valueCalendar={valueCalendar}
        setValue={setValue}
        minDate={minDateCalendar}
      />

      <Form
        form={form}
        onFinish={handleGetListHotelAvailable}
        layout="vertical"
        onFinishFailed={(values) => {
          console.log(values, "values");
        }}
        initialValues={{
          departDate: dayjs(),
          returnDate: dayjs().add(1, "day"),
          startNumber: 0,
        }}
        className="w-[92%] mt-4"
      >
        <div
          style={{
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
          className="rounded-[12px] bg-white p-4 flex flex-col w-full"
        >
          <div className="flex gap-2 items-center justify-start">
            {/* <img src="/images/icon_hotel.png" alt="" /> */}
            <span className="text-[18px] text-[#15284a] font-semibold mb-0">
              Đặt phòng khách sạn trực tuyến
            </span>
          </div>
          <Divider
            variant="dashed"
            style={{ borderColor: "#0064D2" }}
            className="my-4"
            dashed
          />
          <div className="text-[#8b92a5] text-[15px] mb-2">
            Địa điểm khách sạn
          </div>
          <Input
            placeholder="Địa điểm"
            className="h-12 bg-[#f5f6f6] font-semibold text-[15px] text-[#333333] address-input"
            readOnly
            prefix={
              <FontAwesomeIcon
                icon={faLocationDot}
                className="w-[14px]"
                color="#0064D2"
              />
            }
            onClick={() => {
              setOpenDrawerChooseAdd(true);
            }}
            value={filter.address}
          />
          <div className="text-[#8b92a5] text-[15px] my-1">
            Tên khách sạn (Không bắt buộc)
          </div>
          <Select
            showSearch
            placeholder="Tên khách sạn"
            optionFilterProp="label"
            className="h-12 custom-select"
            onSearch={(value) => handleSearchHotel(value)}
            options={filterHotel}
            value={hotelSearch}
            onSelect={(value) => {
              setHotelSearch(value);
            }}
          />

          <div className="text-[#8b92a5] text-[15px] my-1">Hạng khách sạn</div>
          <Select
            placeholder="Hạng khách sạn"
            className="h-12 bg-[#f5f6f6] font-bold"
            onSelect={(value) => {
              setFilter({ ...filter, star: value });
            }}
            value={filter.star}
          >
            <Select.Option key={6} value={6}>
              <span className="font-semibold">Tất cả</span>
            </Select.Option>
            {[1, 2, 3, 4, 5].map((star) => (
              <Select.Option key={star} value={star}>
                <span className="font-semibold"> {star} sao</span>
              </Select.Option>
            ))}
          </Select>

          <Row gutter={12} className="my-1">
            <Col span={12}>
              <div className="text-[#8b92a5] text-[15px] mb-2">
                Ngày nhận phòng
              </div>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Vui lòng chọn ngày nhận phòng!",
                  },
                ]}
                name="departDate"
                className="w-full mb-0"
              >
                <DatePicker
                  className="w-full h-12"
                  placeholder="Chọn ngày"
                  format="DD/MM/YYYY"
                  minDate={dayjs()}
                  allowClear={false}
                  locale={locale} // Thiết lập locale tiếng Việt
                  suffixIcon={
                    <img src={"/images/date.svg"} className="w-[14px]" alt="" />
                  }
                  open={false}
                  onOpenChange={() => onOpenChange("departDate")}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <div className="text-[#8b92a5] text-[15px] mb-2">
                Ngày trả phòng
              </div>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Vui lòng chọn ngày trả phòng!",
                  },
                ]}
                name="returnDate"
                className="w-full mb-0"
              >
                <DatePicker
                  className="w-full h-12"
                  placeholder="Chọn ngày"
                  format="DD/MM/YYYY"
                  minDate={dayjs()}
                  allowClear={false}
                  locale={locale} // Thiết lập locale tiếng Việt
                  suffixIcon={
                    <img src={"/images/date.svg"} className="w-[14px]" alt="" />
                  }
                  open={false}
                  onOpenChange={() => onOpenChange("returnDate")}
                />
              </Form.Item>
            </Col>
          </Row>

          <div className="flex w-full gap-4 justify-between mb-2">
            <div className="w-1/3">
              <CustomInputNumber
                form={form}
                name="numAdults"
                label={
                  <span className="mb-1 text-[15px] font-medium text-[#8b92a5]">
                    Người lớn{" "} <img src="/images/aldult.svg" alt="" />
                  </span>
                }
                min={1}
                max={10}
                defaultValue={1}
              />
            </div>

            <div className="w-1/3">
              <CustomInputNumber
                form={form}
                name="numChilds"
                label={
                  <span className="mb-1 text-[15px] font-medium text-[#8b92a5]">
                    Trẻ em{" "} <img src="/images/child.svg" alt="" />
                  </span>
                }
                min={0}
                max={10}
                defaultValue={1}
              />
            </div>

            <div className="w-1/3">
              <CustomInputNumber
                form={form}
                name="numRooms"
                label={
                  <span className="mb-1 text-[15px] font-medium text-[#8b92a5]">
                    Số phòng{" "}
                  </span>
                }
                min={1}
                max={1000}
                defaultValue={1}
              />
            </div>
          </div>

          <Button
            type="primary"
            htmlType="submit"
            className="w-full h-14 mt-4"
            style={{
              backgroundColor: "#0064D2",
              borderColor: "#0064D2",
              color: "#FFFFFF",
              fontWeight: "bold",
            }}
          >
            <FontAwesomeIcon icon={faSearch} color="white" />
            <span className="text-[16px] font-semibold">Tìm kiếm</span>
          </Button>
        </div>
      </Form>

      <div className="text-center mt-4">
        <span className="text-[#0064D2] font-semibold">
          Hotline hỗ trợ khách hàng:{" "}
        </span>
        <a
          href="tel:0876100800"
          className="font-bold text-[#0064D2] text-[16px]"
        >
          0876.100.800
        </a>
      </div>
      {searched && displayHotels.length > 0 && (
        <div
          className="bg-white p-4 text-center items-center flex mt-4 rounded-[12px] w-[92%] gap-3"
          style={{
            boxShadow: "0px 4px 4px 4px rgba(0, 0, 0, 0.25)",
            justifyContent: "space-around",
          }}
        >
          {/* <Dropdown menu={menuSortTime}> */}
          <Button
            className="bg-white border-[#d9d9d9] text-white focus:bg-white hover:bg-white rounded-[20px]"
            onClick={() => handleSortPriceHotel()}
          >
            {sortBy === "price:asc" && (
              <FontAwesomeIcon
                icon={faArrowUp}
                className="w-[14px]"
                color="#0064D2"
              />
            )}
            {sortBy === "price:desc" && (
              <FontAwesomeIcon
                icon={faArrowDown}
                className="w-[14px]"
                color="#0064D2"
              />
            )}
            <span className="text-black">Giá</span>
          </Button>

          <Button
            className="bg-white border-[#d9d9d9] text-white focus:bg-white hover:bg-white rounded-[20px]"
            onClick={() => handleSortStarHotel()}
          >
            {sortBy === "star:asc" && (
              <FontAwesomeIcon
                icon={faArrowUp}
                className="w-[14px]"
                color="#0064D2"
              />
            )}
            {sortBy === "star:desc" && (
              <FontAwesomeIcon
                icon={faArrowDown}
                className="w-[14px]"
                color="#0064D2"
              />
            )}
            <span className="text-black">Hạng khách sạn</span>
          </Button>
        </div>
      )}

      {displayHotels && displayHotels.length > 0 ? (
        <div className="mt-4 w-[92%]">
          {displayHotels.map((item, index) => (
            <HotelItem
              key={index}
              setOpenChooseHotel={setOpenChooseHotel}
              data={item}
              setHotelSelected={setHotelSelected}
              addPrice={addPrice}
            />
          ))}
        </div>
      ) : (
        <>
          {!loading && searched && displayHotels.length === 0 && (
            <div className="flex flex-col w-[92%] items-center mt-[48px]">
              <div className="text-[24px] text-[#0064D2] font-bold text-center mb-2">
                Không tìm thấy khách sạn trên Ipass
              </div>
              <div className="mb-4 text-center text-[#0064D2]">
                Hiện tại <span className="font-bold">Ipass</span> chưa cập nhật
                phòng khách sạn tại{" "}
                <span className="font-bold">{filter.address}</span> từ ngày
                <span className="font-bold">
                  {" "}
                  {dayjs(form.getFieldValue("departDate")).format("DD-MM-YYYY")}
                </span>{" "}
                đến ngày
                <span className="font-bold">
                  {" "}
                  {dayjs(form.getFieldValue("returnDate")).format("DD-MM-YYYY")}
                </span>
              </div>
              <div className="mb-4 text-center text-[#0064D2]">
                Xin quý khách vui lòng tìm phòng với ngày khác hoặc thay đổi địa
                điểm tìm kiếm.
              </div>
              <Empty description={""} />
            </div>
          )}
        </>
      )}
      {/* 
      {displayHotels.length > 0 && (
        <div className="flex items-center justify-center">
          <Pagination
            current={currentPage}
            className="my-4"
            total={displayHotels.length}
            onChange={(page) => {
              // getPaginatedHotels(hotels, page);
              setCurrentPage(page);
            }}
            pageSize={pageSize}
            showSizeChanger={false}
            showQuickJumper={false}
          />
        </div>
      )} */}
      {openChooseHotel && (
        <ChooseHotel
          hotelSelected={hotelSelected}
          open={openChooseHotel}
          addPrice={addPrice}
          setOpenChooseHotel={setOpenChooseHotel}
          setLoading={setLoading}
          formSearch={form}
          setHotelSelected={setHotelSelected}
          filterSearch={filter}
        />
      )}

      {openDrawerChooseAdd && (
        <div className="location">
          <Drawer
            title={
              <h1 className="text-lg font-bold text-white">
                {chooseAddStep === 1 ? "Chọn đất nước" : "Chọn tỉnh thành"}
              </h1>
            }
            placement="bottom"
            open={openDrawerChooseAdd}
            onClose={() => {
              setTimeout(() => {
                setOpenDrawerChooseAdd(false);
              }, 0);
            }}
            height="100vh"
            loading={loadingLocation}
          >
            {chooseAddStep === 2 && (
              <div
                className="w-full bg-white  text-[#0064d2] mb-4"
                onClick={() => setChooseAddStep(1)}
              >
                <FontAwesomeIcon icon={faAngleLeft} color="#0064d2" />
                <span className="text-[16px]">Quay lại</span>{" "}
              </div>
            )}
            <div className="custom-scroll pb-[64px]">
              {(chooseAddStep === 1 && countries.length > 0) ||
              (chooseAddStep === 2 && provinces.length > 0) ? (
                <Input
                  placeholder="Tìm kiếm địa điểm"
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                  }}
                  className="mb-2"
                />
              ) : (
                <Empty description={"Không có dữ liệu"} />
              )}

              <div className="overflow-auto airport-list">
                {filterCountries.length > 0 && chooseAddStep === 1 && (
                  <>
                    <h4 className="mb-2 font-semibold text-md text-primary">
                      Chọn đất nước
                    </h4>
                    <div className="flex gap-2.5 flex-wrap">
                      {filterCountries.map((c) => (
                        <div
                          key={c.id}
                          className="h-8 flex items-center rounded-lg px-2 bg-[#EDF2F7]"
                          onClick={() => handleSelectAdd(c.id, "country")}
                        >
                          {c?.name}
                        </div>
                      ))}
                    </div>
                  </>
                )}

                {filterProvinces.length > 0 && chooseAddStep === 2 && (
                  <div className="mt-4">
                    <h4 className="mb-2 font-semibold text-md text-primary">
                      Tỉnh/Thành phố
                    </h4>
                    <div className="flex gap-2.5 flex-wrap">
                      {filterProvinces.map((item) => (
                        <div
                          key={item.id}
                          className="h-8 flex items-center rounded-lg px-2 bg-[#EDF2F7]"
                          onClick={() => handleSelectAdd(item.id, "province")}
                        >
                          {item?.name}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {contextHolder}
          </Drawer>
        </div>
      )}
    </div>
  );
};

export default Booking;
